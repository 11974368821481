import axios from "axios"
import qs from "qs"

function nestedAttributes(params) {
    if (Array.isArray(params)) return params

    const result = {}
    Object.keys(params).forEach(k => {
        if (Array.isArray(params[k])) {
            result[k.toString() + "_attributes"] = params[k].map(nestedAttributes)
        } else if (params[k] instanceof Object && !(params[k] instanceof Date))
            result[k.toString() + "_attributes"] = nestedAttributes(params[k])
        else {
            result[k] = params[k]
        }
    })
    return result
}

export default function createService(baseURL, authToken, singular, plural, extra = () => ({})) {
    const instanceFn = () => {
        let _instance = axios.create({
            baseURL: `${baseURL}/${plural}/`,
            headers: { Authorization: authToken ? localStorage.getItem(authToken) : "" }
        })

        _instance.interceptors.request.use(config => {
            config.paramsSerializer = params => {
                return qs.stringify(params, {
                    arrayFormat: "brackets",
                    encode: false
                })
            }

            return config
        })

        return _instance
    }

    return {
        index(params) {
            return instanceFn()
                .get(``, {
                    params
                })
                .then(response => new Promise(resolve => resolve(response.data)))
                .catch(error => {
                    return { error }
                })
        },
        find(id, params = {}) {
            return instanceFn()
                .get(`${id}`, {
                    params
                })
                .then(response => new Promise(resolve => resolve(response.data)))
                .catch(error => {
                    return { error }
                })
        },
        create(object) {
            const render_options = object.render_options
            const include = object.include
            const methods = object.methods

            return instanceFn()
                .post(``, { [singular]: nestedAttributes(object), render_options, include, methods })
                .then(response => new Promise(resolve => resolve(response.data)))
                .catch(error => {
                    return { error }
                })
        },
        update(id, object) {
            const render_options = object.render_options
            const include = object.include
            const methods = object.methods

            return instanceFn()
                .patch(`${id}/`, { [singular]: nestedAttributes(object), render_options, include, methods })
                .then(response => new Promise(resolve => resolve(response.data)))
                .catch(error => {
                    return { error }
                })
        },
        delete(id) {
            return instanceFn().delete(`${id}/`, {})
        },
        member(id, path) {
            return instanceFn().get(`${id}/${path}/`, {})
        },
        collection(path, params) {
            return instanceFn()
                .get(`${path}/`, {
                    params
                })
                .then(response => new Promise(resolve => resolve(response.data)))
                .catch(error => {
                    return { error }
                })
        },
        ...extra(singular, plural, instanceFn)
    }
}
