import { useEffect, useState } from "react"
import { plataformasService } from "utils/clonadorApiServices"
import Admin from "./Admin"
import {
    Button,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    IconButton,
    Tooltip
} from "@material-ui/core"
import { Link as RouterLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { Computer, Create, Settings } from "@material-ui/icons"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
    buttonsContainer: {
        display: "flex",
        justifyContent: "flex-end"
    },
    textSuccess: {
        color: theme.palette.success.main
    },
    textDanger: {
        color: theme.palette.error.main
    },
    textWarning: {
        color: theme.palette.warning.main
    },
    textInfo: {
        color: theme.palette.info.main
    }
}))

export default function Plataformas() {
    const classes = useStyles()
    const [plataformas, setPlataformas] = useState([])
    const [deployando, setDeployando] = useState({})

    useEffect(() => {
        plataformasService.index().then(plataformas => {
            setPlataformas(plataformas)
        })
    }, [])

    function crearApi(plataforma) {
        plataformasService.crearApi(plataforma.id, {}).then(response => {
            if (response.api_state !== "En cola") {
                alert("Hubo un problema al interar crear la API.")
            } else {
                alert("La API se encuentra en cola para ser creada.")
            }
        })
    }

    function deploy(plataforma) {
        setDeployando({ ...deployando, [plataforma.id]: true })
        plataformasService
            .deploy(plataforma.id, {})
            .then(response => {
                setDeployando({ ...deployando, [plataforma.id]: false })
                if (!response.success) {
                    alert("Hubo un problema al interar hacer el deploy.")
                } else {
                    alert("El deploy se esta ejecutando con éxito. El tiempo estimado es de unos 30 minutos.")
                }
            })
            .catch(error => {
                setDeployando({ ...deployando, [plataforma.id]: false })
                alert("Hubo un problema al interar hacer el deploy.")
            })
    }

    return (
        <Admin>
            <div className={classes.buttonsContainer}>
                <Button variant="contained" color="primary" component={RouterLink} to="/plataformas/new">
                    Nuevo
                </Button>
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="right">Nombre Plataforma</TableCell>
                            <TableCell align="right">Sprint</TableCell>
                            <TableCell align="right">URL API</TableCell>
                            <TableCell align="right">Tipo Api</TableCell>
                            <TableCell align="right">Api State</TableCell>
                            <TableCell align="right">Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {plataformas?.map(plataforma => (
                            <TableRow key={plataforma.id}>
                                <TableCell component="th" scope="row">
                                    {plataforma.id}
                                </TableCell>
                                <TableCell align="right">{plataforma.plataforma}</TableCell>
                                <TableCell align="right">{plataforma.sprint}</TableCell>
                                <TableCell align="right">{plataforma.url_api}</TableCell>
                                <TableCell align="right">{plataforma.api_state ? "Propia" : "Postulaciones"}</TableCell>
                                <TableCell
                                    align="right"
                                    className={clsx({
                                        [classes.textSuccess]: plataforma.api_state === "Created",
                                        [classes.textDanger]: plataforma.api_state === "Error",
                                        [classes.textWarning]: plataforma.api_state === "En cola",
                                        [classes.textInfo]: plataforma.api_state === "Creando"
                                    })}
                                >
                                    {plataforma.api_state || "-"}
                                </TableCell>
                                <TableCell align="right">
                                    <Tooltip title="Editar">
                                        <IconButton component={RouterLink} to={`/plataformas/${plataforma.id}/edit`}>
                                            <Create />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Configurar">
                                        <IconButton
                                            component={RouterLink}
                                            to={`/plataformas/${plataforma.id}/configurar`}
                                        >
                                            <Settings />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Crear API">
                                        <IconButton
                                            disabled={!!plataforma.created_api}
                                            onClick={() => crearApi(plataforma)}
                                        >
                                            <Computer />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Deploy">
                                        <IconButton
                                            disabled={deployando[plataforma.id]}
                                            onClick={() => deploy(plataforma)}
                                        >
                                            <CloudUploadIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Admin>
    )
}
