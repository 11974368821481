import { Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { plataformasService } from "utils/clonadorApiServices"
import Admin from "./Admin"
import PlataformaForm from "./PlataformaForm"

export default function PlataformaEdit() {
    const [plataforma, setPlataforma] = useState(null)
    const history = useHistory()
    const { id } = useParams()

    useEffect(() => {
        plataformasService.find(id).then(plataforma => {
            setPlataforma(plataforma)
        })
    }, [id])

    function save(plataforma) {
        plataformasService.update(id, plataforma).then(plataforma => {
            console.log(plataforma)
            history.push("/plataformas")
        })
    }

    return (
        <Admin>
            <Typography variant="h6">Crear plataforma</Typography>
            {plataforma && <PlataformaForm plataforma={plataforma} onSave={save} disablePlataforma={true} />}
        </Admin>
    )
}
