import Home from "components/Home"
import PlataformaNew from "components/PlataformaNew"
import PlataformaEdit from "components/PlataformaEdit"
import Plataformas from "components/Plataformas"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { CssBaseline } from "@material-ui/core"

import { createMuiTheme } from "@material-ui/core"
import { unstable_createMuiStrictModeTheme } from "@material-ui/core"
import { ThemeProvider } from "@material-ui/styles"
import PlataformaConfigurar from "components/PlataformaConfigurar"
// fix para que deje de tirar warnings en modo estricto por createMuiTheme
const createTheme = process.env.NODE_ENV === "production" ? createMuiTheme : unstable_createMuiStrictModeTheme
const theme = createTheme()

function App() {
    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router>
                    <Switch>
                        <Route path="/plataformas/new" component={PlataformaNew} />
                        <Route path="/plataformas/:id/configurar" component={PlataformaConfigurar} />
                        <Route path="/plataformas/:id/edit" component={PlataformaEdit} />
                        <Route path="/plataformas" component={Plataformas} />
                        <Route path="/" component={Home} />
                    </Switch>
                </Router>
            </ThemeProvider>
        </>
    )
}

export default App
