import { Toolbar, Typography, AppBar, Drawer, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Computer } from "@material-ui/icons"
import { forwardRef, useMemo } from "react"
import { Link as RouterLink } from "react-router-dom"

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1
    },
    drawer: {
        width: 240,
        flexShrink: 0
    },
    drawerPaper: {
        width: 240
    },
    drawerContainer: {
        overflow: "auto"
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    }
}))

function ListItemLink(props) {
    const { icon, primary, to } = props

    const CustomLink = useMemo(() => forwardRef((linkProps, ref) => <RouterLink ref={ref} to={to} {...linkProps} />), [
        to
    ])

    return (
        <li>
            <ListItem button component={CustomLink}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    )
}

export default function Admin({ children }) {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" noWrap>
                        Clonador
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer className={classes.drawer} variant="permanent" classes={{ paper: classes.drawerPaper }}>
                <Toolbar />
                <div className={classes.drawerContainer}>
                    <List>
                        <ListItemLink to="/plataformas" primary="Plataformas" icon={<Computer />} />
                    </List>
                </div>
            </Drawer>
            <main className={classes.content}>
                <Toolbar />
                {children}
            </main>
        </div>
    )
}
