import React, { useCallback, useEffect, useState } from "react"
import { plataformasService, s3PolicyService } from "utils/clonadorApiServices"
import { Grid, Paper, Typography, TextField, FormControlLabel, Checkbox, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import Admin from "./Admin"
import File from "./File"
import { useHistory, useParams } from "react-router-dom"
import axios from "axios"
import qs from "qs"

const useStyles = makeStyles(theme => ({
    section: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    title: {
        marginBottom: theme.spacing(2)
    }
}))

const identity = x => x
const checked = event => event.target.checked

export default function PlataformaConfigurar() {
    const classes = useStyles()
    const [configuracion, setConfiguracion] = useState(null)
    const [configuracionId, setConfiguracionId] = useState(null)
    const [plataforma, setPlataforma] = useState(null)
    const { id } = useParams()
    const history = useHistory()

    useEffect(() => {
        plataformasService.find(id).then(plataforma => {
            setPlataforma(plataforma)

            const params = { configuracion: { plataforma: plataforma.plataforma } }
            const stringifiedParams = qs.stringify(params, {
                arrayFormat: "brackets",
                encode: false
            })

            axios
                .get(`${plataforma.url_api}/configuraciones?${stringifiedParams}`)
                .then(response => response.data)
                .then(configuraciones => {
                    if (configuraciones.length > 0) {
                        setConfiguracionId(configuraciones[0].id)
                        setConfiguracion(configuraciones[0].configuracion)
                    } else {
                        setConfiguracion({
                            primary_color: "#0A447C",
                            secondary_color: "#8cc43c",
                            landing: {
                                background_color: "#f3f3f3"
                            }
                        })
                    }
                })
        })
    }, [id])

    function addLogoExtra() {
        const logos = configuracion.footer?.logo_extras?.slice() || []
        logos.push("")

        setConfiguracion({
            ...configuracion,
            footer: {
                ...configuracion.footer,
                logo_extras: logos
            }
        })
    }

    function changeLogo(index) {
        return value => {
            const logos = configuracion.footer?.logo_extras?.slice() || []
            logos[index] = value

            setConfiguracion({
                ...configuracion,
                footer: {
                    ...configuracion.footer,
                    logo_extras: logos
                }
            })
        }
    }

    function deleteLogo(index) {
        return () => {
            const logos = configuracion.footer?.logo_extras?.slice() || []
            logos.splice(index, 1)

            setConfiguracion({
                ...configuracion,
                footer: {
                    ...configuracion.footer,
                    logo_extras: logos
                }
            })
        }
    }

    function save() {
        const configuracionObj = {
            configuracion: {
                configuracion
            }
        }

        if (configuracionId) {
            axios.patch(`${plataforma.url_api}/configuraciones/${configuracionId}`, configuracionObj).then(response => {
                console.log(response)
                history.push("/plataformas")
            })
        } else {
            configuracionObj.configuracion.plataforma = plataforma.plataforma

            axios.post(`${plataforma.url_api}/configuraciones`, configuracionObj).then(response => {
                console.log(response)
                history.push("/plataformas")
            })
        }
    }

    function onChangeKey(key, valueExtractor = event => event.target.value) {
        return event => {
            const obj = { ...configuracion }
            const segments = key.split(".")
            let point = obj
            for (let i = 0; i < segments.length; i++) {
                const segment = segments[i]
                if (i == segments.length - 1) {
                    point[segment] = valueExtractor(event)
                } else {
                    point[segment] = point[segment] || {}
                }

                point = point[segment]
            }

            setConfiguracion({
                ...obj
            })
        }
    }

    return (
        <Admin>
            {configuracion && (
                <>
                    <Paper className={classes.section}>
                        <Typography className={classes.title} variant="h6" component="h2">
                            General
                        </Typography>
                        <Grid container>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Mail de contacto"
                                    placeholder="contacto@becastechint.org.ar"
                                    margin="normal"
                                    value={configuracion.mail_contacto || ""}
                                    onChange={onChangeKey("mail_contacto")}
                                />

                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Mail de consultas"
                                    placeholder="consultas@becastechint.org.ar"
                                    margin="normal"
                                    value={configuracion.mail_consultas || ""}
                                    onChange={onChangeKey("mail_consultas")}
                                />

                                <File
                                    variant="outlined"
                                    fullWidth
                                    label="Favicons"
                                    placeholder="https://bucket-techin.s3.us-west-1.amazonaws.com/assets/techint/favicons"
                                    margin="normal"
                                    url={configuracion.base_url_favicons || ""}
                                    onChange={onChangeKey("base_url_favicons", identity)}
                                    accept="application/zip"
                                    unzip={true}
                                    plataforma={plataforma.plataforma}
                                />

                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Nombre del Identificador del Pais"
                                    placeholder="DNI"
                                    margin="normal"
                                    value={configuracion.label_identificador_pais || ""}
                                    onChange={onChangeKey("label_identificador_pais")}
                                />

                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    type="color"
                                    margin="normal"
                                    label="Color Primario"
                                    value={configuracion.primary_color || ""}
                                    onChange={onChangeKey("primary_color")}
                                />

                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    type="color"
                                    margin="normal"
                                    label="Color Secundario"
                                    value={configuracion.secondary_color || ""}
                                    onChange={onChangeKey("secondary_color")}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.section}>
                        <Typography className={classes.title} variant="h6" component="h2">
                            Landing
                        </Typography>

                        <Grid container>
                            <Grid item md={6} xs={12}>
                                <FormControlLabel
                                    label="Usar una imágen de fondo"
                                    control={
                                        <Checkbox
                                            checked={configuracion.landing?.use_background_image || false}
                                            onChange={onChangeKey("landing.use_background_image", checked)}
                                        />
                                    }
                                />
                                <File
                                    variant="outlined"
                                    fullWidth
                                    label="Imágen de fondo del landing"
                                    placeholder="http://www.example.com/image.jpg"
                                    margin="normal"
                                    url={configuracion.landing?.background_image || ""}
                                    onChange={onChangeKey("landing.background_image", identity)}
                                />
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    type="color"
                                    margin="normal"
                                    label="Color de fondo del landing"
                                    value={configuracion.landing?.background_color || ""}
                                    onChange={onChangeKey("landing.background_color")}
                                />
                                <File
                                    variant="outlined"
                                    fullWidth
                                    label="Logo en la parte inferior del Login"
                                    placeholder="http://www.example.com/image.jpg"
                                    margin="normal"
                                    url={configuracion.landing?.logo_login || ""}
                                    onChange={onChangeKey("landing.logo_login", identity)}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.section}>
                        <Typography className={classes.title} variant="h6" component="h2">
                            Navbar
                        </Typography>
                        <Grid container>
                            <Grid item md={6} xs={12}>
                                <File
                                    variant="outlined"
                                    fullWidth
                                    label="Logo del Navbar"
                                    placeholder="http://www.example.com/image.jpg"
                                    url={configuracion.navbar?.logo || ""}
                                    margin="normal"
                                    onChange={onChangeKey("navbar.logo", identity)}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.section}>
                        <Typography className={classes.title} variant="h6" component="h2">
                            Footer
                        </Typography>
                        <Grid container>
                            <Grid item md={6} xs={12}>
                                <File
                                    variant="outlined"
                                    fullWidth
                                    label="Logo del Footer"
                                    placeholder="http://www.example.com/image.jpg"
                                    url={configuracion.footer?.logo || ""}
                                    margin="normal"
                                    onChange={onChangeKey("footer.logo", identity)}
                                />

                                <div>
                                    {configuracion.footer?.logo_extras?.map((logo, index) => (
                                        <div key={index}>
                                            <File
                                                variant="outlined"
                                                fullWidth
                                                label={`Logo extra ${index + 1}`}
                                                placeholder="http://www.example.com/image.jpg"
                                                url={logo || ""}
                                                margin="normal"
                                                onChange={changeLogo(index)}
                                            />
                                            <Button color="secondary" onClick={deleteLogo(index)}>
                                                Borrar
                                            </Button>
                                        </div>
                                    ))}
                                    <Button variant="contained" color="primary" onClick={addLogoExtra}>
                                        Agregar Logo Extra
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.section}>
                        <Typography className={classes.title} variant="h6" component="h2">
                            Registro
                        </Typography>
                        <Grid container>
                            <Grid item md={6} xs={12}>
                                <Typography className={classes.title} variant="subtitle1" component="h3">
                                    Campos habilitados
                                </Typography>
                                <FormControlLabel
                                    label="Nombre"
                                    control={
                                        <Checkbox
                                            checked={configuracion.registro?.enabled?.nombre || false}
                                            onChange={onChangeKey("registro.enabled.nombre", checked)}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Apellido Paterno"
                                    control={
                                        <Checkbox
                                            checked={configuracion.registro?.enabled?.apellido_paterno || false}
                                            onChange={onChangeKey("registro.enabled.apellido_paterno", checked)}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Fecha Nacimiento"
                                    control={
                                        <Checkbox
                                            checked={configuracion.registro?.enabled?.fecha_nacimiento || false}
                                            onChange={onChangeKey("registro.enabled.fecha_nacimiento", checked)}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Tipo Telefono"
                                    control={
                                        <Checkbox
                                            checked={configuracion.registro?.enabled?.tipo_telefono || false}
                                            onChange={onChangeKey("registro.enabled.tipo_telefono", checked)}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Codigo País Teléfono"
                                    control={
                                        <Checkbox
                                            checked={configuracion.registro?.enabled?.codigo_pais_telefono || false}
                                            onChange={onChangeKey("registro.enabled.codigo_pais_telefono", checked)}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Código Área Teléfono"
                                    control={
                                        <Checkbox
                                            checked={configuracion.registro?.enabled?.codigo_area_telefono || false}
                                            onChange={onChangeKey("registro.enabled.codigo_area_telefono", checked)}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Teléfono"
                                    control={
                                        <Checkbox
                                            checked={configuracion.registro?.enabled?.telefono || false}
                                            onChange={onChangeKey("registro.enabled.telefono", checked)}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Institucion"
                                    control={
                                        <Checkbox
                                            checked={configuracion.registro?.enabled?.institucion_id || false}
                                            onChange={onChangeKey("registro.enabled.institucion_id", checked)}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Lugares"
                                    control={
                                        <Checkbox
                                            checked={configuracion.registro?.enabled?.lugares || false}
                                            onChange={onChangeKey("registro.enabled.lugares", checked)}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Calle"
                                    control={
                                        <Checkbox
                                            checked={configuracion.registro?.enabled?.calle || false}
                                            onChange={onChangeKey("registro.enabled.calle", checked)}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Número"
                                    control={
                                        <Checkbox
                                            checked={configuracion.registro?.enabled?.numero || false}
                                            onChange={onChangeKey("registro.enabled.numero", checked)}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Código Postal"
                                    control={
                                        <Checkbox
                                            checked={configuracion.registro?.enabled?.codigo_postal || false}
                                            onChange={onChangeKey("registro.enabled.codigo_postal", checked)}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Barrio"
                                    control={
                                        <Checkbox
                                            checked={configuracion.registro?.enabled?.barrio || false}
                                            onChange={onChangeKey("registro.enabled.barrio", checked)}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.section}>
                        <Typography className={classes.title} variant="h6" component="h2">
                            Mailings
                        </Typography>
                        <Grid container>
                            <Grid item md={6} xs={12}>
                                <File
                                    variant="outlined"
                                    fullWidth
                                    label="Imágen del header"
                                    placeholder="http://www.example.com/image.jpg"
                                    url={configuracion.header_mails || ""}
                                    margin="normal"
                                    onChange={onChangeKey("header_mails", identity)}
                                />

                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Desde"
                                    placeholder="'Becas al Mérito' <no-reply@becastechint.org.ar>"
                                    margin="normal"
                                    value={configuracion.from_mails || ""}
                                    onChange={onChangeKey("from_mails")}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Button variant="contained" color="primary" onClick={save}>
                        Guardar
                    </Button>
                </>
            )}
        </Admin>
    )
}
