import { Typography } from "@material-ui/core"
import { useMemo } from "react"
import { useHistory } from "react-router-dom"
import { plataformasService } from "utils/clonadorApiServices"
import Admin from "./Admin"
import PlataformaForm from "./PlataformaForm"

export default function PlataformaNew() {
    const plataforma = useMemo(
        () => ({
            plataforma: "",
            sprint: "69",
            url_api: "https://api.postulaciones-clones.ogr.cl"
        }),
        []
    )
    const history = useHistory()

    function save(plataforma) {
        plataformasService.create(plataforma).then(plataforma => {
            console.log(plataforma)
            history.push("/plataformas")
        })
    }

    return (
        <Admin>
            <Typography variant="h6">Crear plataforma</Typography>
            <PlataformaForm plataforma={plataforma} onSave={save} />
        </Admin>
    )
}
