import createService from "./createService"

const BASE_URL = process.env.REACT_APP_BASE_URL
let createClonadorService = (singular, plural, extra = (singular, plural, instanceFn) => ({})) =>
    createService(BASE_URL, null, singular, plural, extra)

export const plataformasService = createClonadorService(
    "plataforma",
    "plataformas",
    (singular, plural, instanceFn) => ({
        crearApi(id, params) {
            return instanceFn()
                .post(`${id}/crear_api`, params)
                .then(response => new Promise(resolve => resolve(response.data)))
                .catch(error => {
                    return { error }
                })
        },
        deploy(id, params) {
            return instanceFn()
                .post(`${id}/deployar`, params)
                .then(response => new Promise(resolve => resolve(response.data)))
                .catch(error => {
                    return { error }
                })
        }
    })
)
export const s3PolicyService = createClonadorService("s3_policy", "s3_policy", (singular, plural, instanceFn) => ({
    unzipS3(params) {
        return instanceFn()
            .post("unzip", params)
            .then(response => new Promise(resolve => resolve(response.data)))
            .catch(error => {
                return { error }
            })
    }
}))
