import { useState } from "react"
import { Grid, TextField, Button } from "@material-ui/core"

function checkRequired(value) {
    return !value ? "El campo es requerido." : null
}

const validations = {
    plataforma: [checkRequired],
    sprint: [checkRequired],
    url_api: []
}

export default function PlataformaForm({ plataforma: plataformaProp, onSave, disablePlataforma }) {
    const [plataforma, setPlataforma] = useState(plataformaProp)
    const [errors, setErrors] = useState({
        plataforma: null,
        sprint: null,
        url_api: null
    })

    function changePlataforma(key) {
        return event => {
            const value = event.target.value

            setPlataforma({
                ...plataforma,
                [key]: value
            })
        }
    }

    function checkValidations() {
        let isValid = true

        const newErrors = Object.keys(validations).reduce((acc, key) => {
            const fieldValidations = validations[key]

            acc[key] = fieldValidations.map(validation => validation(plataforma[key])).filter(x => !!x)[0]

            if (acc[key]) {
                isValid = false
            }

            return acc
        }, {})

        setErrors(newErrors)

        return isValid
    }

    function onSaveButton() {
        if (checkValidations()) {
            onSave(plataforma)
        }
    }

    return (
        <div>
            <Grid container>
                <Grid item md={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Plataforma"
                        value={plataforma.plataforma || ""}
                        required
                        onChange={changePlataforma("plataforma")}
                        error={!!errors.plataforma}
                        helperText={errors.plataforma}
                        disabled={disablePlataforma}
                        margin="normal"
                    />
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Sprint"
                        value={plataforma.sprint || ""}
                        required
                        onChange={changePlataforma("sprint")}
                        error={!!errors.sprint}
                        helperText={errors.sprint}
                        margin="normal"
                    />
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="URL API"
                        value={plataforma.url_api || ""}
                        onChange={changePlataforma("url_api")}
                        error={!!errors.url_api}
                        helperText={
                            errors.url_api
                                ? errors.url_api
                                : "Este campo sirve para cambiar la URL de la API a la que apunta esta plataforma. Dejar vacío si no está seguro."
                        }
                        margin="normal"
                    />

                    <Button variant="contained" color="primary" onClick={onSaveButton}>
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}
