import { makeStyles } from "@material-ui/core/styles"
import { TextField, Button, CircularProgress } from "@material-ui/core"
import { useMemo, useState } from "react"
import { s3PolicyService } from "utils/clonadorApiServices"

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: ({ margin }) =>
            margin == "normal" ? theme.spacing(2) : margin == "dense" ? theme.spacing(1) : null,
        marginBottom: ({ margin }) =>
            margin == "normal" ? theme.spacing(1) : margin == "dense" ? theme.spacing(0.5) : null
    },
    inputContainer: {
        "& > input": {
            display: "none"
        },
        "& > .MuiFormControl-marginDense": {
            marginTop: 0,
            marginBottom: 0
        },
        display: "flex"
    },
    fileButton: {
        marginLeft: theme.spacing(1),
        height: "100%"
    },
    wrapper: {
        position: "relative"
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginLeft: "-10px",
        marginTop: "-12px"
    },
    image: {
        display: "block"
    }
}))

let seq = 1

export default function File({ url, onChange, margin, unzip, plataforma, accept, ...props }) {
    const classes = useStyles({ margin })
    const [uploading, setUploading] = useState(false)
    const isImage = useMemo(() => {
        const segments = url.split(".")

        return ["jpg", "jpeg", "png", "bmp"].includes(segments[segments.length - 1].toLowerCase())
    }, [url])

    const id = useMemo(() => {
        return seq++
    }, [])

    function onChooseFile(event) {
        const file = event.target.files[0]
        const key = `clonador/${file.name}`

        setUploading(true)

        s3PolicyService.index({ key }).then(policy => {
            const xhr = new XMLHttpRequest()
            const keys = ["AWSAccessKeyId", "Content-Type", "acl", "key", "policy", "signature"]
            const data = new FormData()

            for (const key in keys) {
                data.append(keys[key], policy[keys[key]])
            }

            data.append("file", file)
            xhr.onreadystatechange = () => {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    if (unzip) {
                        s3PolicyService.unzipS3({ key: policy.key, plataforma }).then(response => {
                            onChange(`https://s3-us-west-1.amazonaws.com/bucket-techin/${response.location}`)
                            setUploading(false)
                        })
                    } else {
                        onChange(`https://s3-us-west-1.amazonaws.com/bucket-techin/${policy.key}`)
                        setUploading(false)
                    }
                }
            }
            xhr.open("POST", "https://s3-us-west-1.amazonaws.com/bucket-techin/", true)
            xhr.send(data)
        })
    }

    return (
        <div className={classes.root}>
            <div className={classes.inputContainer}>
                <input type="file" id={`file-${id}`} accept={accept} onChange={onChooseFile} />
                <TextField
                    {...props}
                    margin={margin == "dense" ? margin : "none"}
                    onChange={event => onChange(event.target.value)}
                    value={url || ""}
                />
                <label htmlFor={`file-${id}`} className={classes.wrapper}>
                    <Button
                        className={classes.fileButton}
                        variant="contained"
                        color="primary"
                        component="span"
                        disabled={uploading}
                    >
                        Subir
                    </Button>
                    {uploading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </label>
            </div>
            {isImage && <img src={url} className={classes.image} />}
        </div>
    )
}
